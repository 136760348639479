import React from 'react';

import styles from './styles.module.css';

const ModalBoxInline = ({ data }) => (
  <>
    <div className={styles.backdrop} />
    <div className={styles.modalBox}>
      {data}
    </div>
  </>
);

export default ModalBoxInline;
